import { DataHandler } from 'o365-dataobject';
import { API } from 'o365-modules';
import { alert } from 'o365-vue-services';
//import dateUtils from 'o365.modules.utils.date.js';
//import DataObject from 'o365.modules.DataObject.ts';

export default class InsightsDataHandler extends DataHandler {
    private _logTable: string;

    duration:string = "12:00:00";

    get url() {
        return super._url;
    }

    constructor(pLogTable: string) {
        super(null);
        super._url = "api/logs";
        this._logTable = pLogTable;
    }

    setDataObject(pDataObject: any) {
        super._dataObject = pDataObject;
    }
    
    async request(pType: string, pData: any, _pHeaders?: any, _pOptions?: any) {
        switch (pType) {
            case "retrieve":
                return this._query(pData);
        }

        return null;
    }

    private _generateQuery(pData: any) {
        const vFields = pData.fields.map((x:any)=>x.name.replace("_","."));

        if (pData.whereClause) {
            pData.whereClause = "| where " + pData.whereClause.replace(")","").replace("(","");
        }

        const vData = {};

        vData['query'] = `${this._logTable} ${pData.whereClause} | project ${vFields.join(", ")} | sort by TimeGenerated desc | take ${pData.maxRecords}`;

        if (pData.range) {
             vData['timeRange'] = {
                //fromDate:dateUtils.format(pData.range[0],'yyyy-MM-dd hh:mm:ii'),
                //toDate:dateUtils.format(pData.range[1],'yyyy-MM-dd hh:mm:ii'),
                start:pData.range[0],
                end:pData.range[1],
            }
        } else {
            vData['timeRange'] = {
                duration:pData.duration??this.duration,
            }
        }
     
        return vData;
    } 

    private async _query(pOptions: any) {
        if(pOptions.clear) return [];
      
        const vResults =  await API.requestPost("api/logs", JSON.stringify(this._generateQuery(pOptions)));
        
        if (vResults.errors) {
            alert(vResults.title);
            throw vResults;
        }

        return this.toJsonObject(vResults.Rows);
    }

    private toJsonObject(pData: Array<any>) {
        const vReturn:Array<any> = [];

        pData.forEach((row) => {
            const vTmp: any = {};
            
            this._dataObject.fields.fields.forEach((field, index) => {
                vTmp[field.name] = row[index];
            });

            vReturn.push(vTmp);
        });

        return vReturn;
    }
}
